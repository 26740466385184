import React from 'react'
import PageLayout from './PageLayout'
import { SidebarEvents } from '../sidebar'
import LayoutSection from './LayoutSection'

const PageLayoutEvents = ({ heading, children, ...props }) => (
  <PageLayout
    {...props}
    sideNode={<SidebarEvents />}
  >
    <LayoutSection
      headingAs="h1"
      heading={heading}
    >
      {children}
    </LayoutSection>
  </PageLayout>
)

export default PageLayoutEvents
