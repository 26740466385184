import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import WidgetLatestPosts from './WidgetLatestPosts'
import WidgetTodaysEvents from './WidgetTodaysEvents'
import WidgetEventCalendar from './WidgetEventCalendar'

const SidebarEvents = () => {
  const { wp: data } = useStaticQuery(graphql`
    query EventsSidebarQuery {
      wp {
        latestPosts: posts(
          first: 5
          where: {
            status: PUBLISH
            language: DEFAULT
            orderby: {
              field: DATE
              order: DESC
            }
          }
        ) {
          nodes {
            ...PostFields
          }
        }
      }
    }
  `)

  return (
    <aside>
      <WidgetTodaysEvents />
      <WidgetEventCalendar />
      <WidgetLatestPosts posts={data.latestPosts.nodes} />
    </aside>
  )
}

export default SidebarEvents
