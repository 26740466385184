import React, { lazy } from 'react'
import {
  Box, Heading, Link, List, ListItem, Parsed, Placeholder,
} from '../ui'
import { Lazy } from '../content'
import EventDuration from './EventDuration'
import { PostContent, parsePostContent } from '../posts'

const MapPosition = lazy(() => import('../ui/MapPosition'))

const EventTemplate = ({
  content,
  title,
  location,
  startDate,
  startTime,
  endDate,
  endTime,
  allDay,
  entranceFee,
}) => {
  const lat = location?.lat
  const lng = location?.lat
  const locationString = [location?.town, location?.address]
    .filter((v) => !!v)
    .join(', ')

  return (
    <Box as="article">
      <Parsed
        as="h1"
        fontSize={[5, 6]}
        component={Heading}
      >
        {title}
      </Parsed>

      <List
        my={4}
        lineHeight={1.5}
      >
        <ListItem>
          <Box
            mr={1}
            as="strong"
          >
            Időpont:
          </Box>
          <EventDuration
            startDate={startDate}
            endDate={endDate}
            startTime={startTime}
            endTime={endTime}
            isAllDay={allDay}
          />
        </ListItem>
        {
          location
            ? (
              <ListItem>
                <Box
                  as="strong"
                  mr={1}
                >
                  Helyszín:
                </Box>
                <Link
                  variant="primary"
                  to={location.uri}
                >
                  {location.title}
                </Link>
              </ListItem>
            ) : null
        }
        <ListItem>
          <Box
            mr={1}
            as="strong"
          >
            Belépődíj:
          </Box>
          {entranceFee || 'Ingyenes'}
        </ListItem>
      </List>

      <PostContent>
        {parsePostContent(content)}
      </PostContent>

      {
        (lat && lng) || locationString
          ? (
            <Lazy fallback={<Placeholder height={300} />}>
              <MapPosition
                lat={lat}
                lng={lng}
                title={location?.title}
                address={locationString}
                my={4}
                mx={[-3, -3, 0]}
              />
            </Lazy>
          ) : null
      }
    </Box>
  )
}

export default EventTemplate
