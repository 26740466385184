import React from 'react'
import { graphql } from 'gatsby'
import { PageLayoutEvents } from '../components/layout'
import { EventTemplate } from '../components/events'
import { MetaTagsMustHave, MetaTagsEvent, SchemaEvent } from '../components/seo'

const Event = ({ data, location }) => {
  const { event } = data.wp

  return (
    <>
      <MetaTagsMustHave
        {...event.seo}
      />
      <MetaTagsEvent
        {...event}
        {...event.seo}
      />
      <SchemaEvent
        {...event}
        {...event.seo}
        url={location.pathname}
        title={event.title}
      />
      <PageLayoutEvents>
        <EventTemplate {...event} />
      </PageLayoutEvents>
    </>
  )
}

export default Event

export const pageQuery = graphql`
  query EventById($uri: ID!) {
    wp {
      event(
        id: $uri
        idType: URI
      ) {
        ...EventDetailsFields
        seo {
          ...SeoFields
        }
      }
    }
  }
`
